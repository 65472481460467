import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commonReduxType } from "../utils/common";
import { dlt, get, post, put } from "../api/api";
import { openAlert } from "./alertSlice";
import {
  ACCESS_DATA_FILE_MESSAGE,
  DELETE_DATA_FILE_MESSAGE,
  EDIT_DATA_FILE_NAME_MESSAGE,
  LIST_DATA_FILE_MESSAGE,
  UPLOAD_DATA_FILE_MESSAGE,
} from "../const/alertMessage";

// ========== INITIALSTATE ==========
const initialState = {
  upload: commonReduxType,
  delete_file: commonReduxType,
  edit_file: commonReduxType,
  list_data_source: { ...commonReduxType, data: [] },
  set_file_access: commonReduxType,
};

// ========== API CALL UPLAOAD CSV FILE ==========
export const upload = createAsyncThunk(
  "uploadFile",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/aimodels/uploadcsv/", "", data);
      dispatch(
        openAlert({ message: UPLOAD_DATA_FILE_MESSAGE[0], severity: "success" })
      );

      return response;
    } catch (error) {
      let message = error?.response?.data?.error || UPLOAD_DATA_FILE_MESSAGE[1];
      if (error?.response?.status === 403) {
        message = UPLOAD_DATA_FILE_MESSAGE[2];
      }
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL DATASOURCE LIST ==========
export const listDatasource = createAsyncThunk(
  "datasourceList",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await get("/aimodels/list-datasource/");

      return response;
    } catch (error) {
      const message = LIST_DATA_FILE_MESSAGE[0];
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL EDIT CSV FILE NAME ==========
export const editDatasetName = createAsyncThunk(
  "editDatasetName",
  async ({ datafile_id, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await put(
        `/aimodels/edit-datasource/${datafile_id}`,
        "",
        data
      );
      dispatch(
        openAlert({
          message: EDIT_DATA_FILE_NAME_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.error || EDIT_DATA_FILE_NAME_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL DELETE CSV FILE ==========
export const deleteDataSet = createAsyncThunk(
  "deleteFile",
  async (datafile_id, { dispatch, rejectWithValue }) => {
    try {
      const response = await dlt(
        `/aimodels/delete-datasource/${datafile_id}`,
        ""
      );
      dispatch(
        openAlert({ message: DELETE_DATA_FILE_MESSAGE[0], severity: "success" })
      );
      return response;
    } catch (error) {
      const message = DELETE_DATA_FILE_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL GIVE ACCESS CSV FILE ==========
export const setAccessOfDataFile = createAsyncThunk(
  "setAccessOfDataFile",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post(`/aimodels/set-datasource-access/`, "", data);
      dispatch(
        openAlert({ message: ACCESS_DATA_FILE_MESSAGE[0], severity: "success" })
      );
      return response;
    } catch (error) {
      const message = ACCESS_DATA_FILE_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== FILES AND DATASOURCE REDUCER ==========
const dataSourceSlice = createSlice({
  name: "dataSourceSlice",
  initialState,
  reducers: {
    emptyDeleteDatasource: (state) => {
      state.delete_file = {
        ...state.delete_file,
        is_success: false,
        loading: false,
        data: {},
      };
    },
    emptyEditDatasource: (state) => {
      state.edit_file = {
        ...state.edit_file,
        is_success: false,
        loading: false,
        data: {},
      };
    },
    emptyUploadDatasource: (state) => {
      state.upload = {
        ...state.upload,
        is_success: false,
        loading: false,
        data: {},
      };
    },
    emptySetAccessOfDataSource: (state) => {
      state.set_file_access = {
        ...state.set_file_access,
        is_success: false,
        loading: false,
        data: {},
      };
    },
    setListDataSource: (state, action) => {
      state.list_data_source = {
        ...state.list_data_source,
        data: [...action.payload],
      };
    },
  },
  extraReducers: (builder) => {
    //  =================================== UPLOAD CASES ===================================
    builder.addCase(upload.pending, (state, action) => {
      state.upload = {
        ...state.upload,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(upload.fulfilled, (state, action) => {
      state.upload = {
        ...state.upload,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(upload.rejected, (state, action) => {
      state.upload = {
        ...state.upload,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  ===================================  UPLOAD CASES ===================================

    //  ===================================  DELETE FILE CASES  ===================================
    builder.addCase(deleteDataSet.pending, (state, action) => {
      state.delete_file = {
        ...state.delete_file,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(deleteDataSet.fulfilled, (state, action) => {
      state.delete_file = {
        ...state.delete_file,
        loading: false,
        is_success: true,
        data: action.payload,
      };
    });
    builder.addCase(deleteDataSet.rejected, (state, action) => {
      state.delete_file = {
        ...state.delete_file,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== DELETE FILE CASES ===================================

    //  =================================== DELETE FILE CASES ===================================
    builder.addCase(editDatasetName.pending, (state, action) => {
      state.edit_file = {
        ...state.edit_file,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(editDatasetName.fulfilled, (state, action) => {
      state.edit_file = {
        ...state.edit_file,
        loading: false,
        is_success: true,
        data: action.payload,
      };
    });
    builder.addCase(editDatasetName.rejected, (state, action) => {
      state.edit_file = {
        ...state.edit_file,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== DELETE FILE CASES ===================================

    //  =================================== LIST UPLOADED FILE CASES ===================================
    builder.addCase(listDatasource.pending, (state, action) => {
      state.list_data_source = {
        ...state.list_data_source,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(listDatasource.fulfilled, (state, action) => {
      state.list_data_source = {
        ...state.list_data_source,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(listDatasource.rejected, (state, action) => {
      state.list_data_source = {
        ...state.list_data_source,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== LIST UPLOADED FILE CASES ===================================

    //  =================================== ACCESS DATA FILE CASES ===================================
    builder.addCase(setAccessOfDataFile.pending, (state, action) => {
      state.set_file_access = {
        ...state.set_file_access,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(setAccessOfDataFile.fulfilled, (state, action) => {
      state.set_file_access = {
        ...state.set_file_access,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(setAccessOfDataFile.rejected, (state, action) => {
      state.set_file_access = {
        ...state.set_file_access,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== ACCESS DATA FILE CASES ===================================
  },
});

export const {
  emptyDeleteDatasource,
  emptyEditDatasource,
  emptyUploadDatasource,
  emptySetAccessOfDataSource,
  setListDataSource,
} = dataSourceSlice.actions;

export default dataSourceSlice.reducer;
