import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commonReduxType } from "../utils/common";
import { dlt, get, post } from "../api/api";
import { openAlert } from "./alertSlice";
import {
  GET_TEMPLATE_DATA_MESSAGE,
  SCHEDULE_REPORT_CHECK_DATA_MESSAGE,
  SCHEDULE_REPORT_CREATE_MESSAGE,
  SCHEDULE_REPORT_DELETE_MESSAGE,
  SCHEDULE_REPORT_LIST_MESSAGE,
  SCHEDULE_REPORT_UPDATE_STATUS_MESSAGE,
  WRITTEN_REPORT_CREATE_MESSAGE,
  WRITTEN_REPORT_DELETE_MESSAGE,
  WRITTEN_REPORT_LIST_MESSAGE,
  WRITTEN_REPORT_RESEND_MESSAGE,
} from "../const/alertMessage";

// ========== INITIALSTATE ==========
const initialState = {
  // ==== WRITTEN REPORTS STATE ====
  create_written_report: commonReduxType,
  get_written_report_template: commonReduxType,
  get_written_report_list: { ...commonReduxType, data: [] },
  delete_written_report: commonReduxType,
  resend_written_report: commonReduxType,

  // === SCHEDULE REPORT STATE ====
  create_schedule_report: commonReduxType,
  get_schedule_report_list: { ...commonReduxType, data: [] },
  delete_schedule_report: commonReduxType,
  update_schedule_report_status: commonReduxType,
  check_schedule_report_data: commonReduxType,
};

// ========== API CALL CREATE WRITTEN REPORT ==========
export const createWrittenReport = createAsyncThunk(
  "createWrittenReport",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post(
        "/aimodels/create-written-reports/",
        "",
        data
      );
      dispatch(
        openAlert({
          message: WRITTEN_REPORT_CREATE_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.error || WRITTEN_REPORT_CREATE_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL GET TEMPLATES DATA  ==========
export const getTemplateData = createAsyncThunk(
  "getTemplateData",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/aimodels/get-template-data/", "", data);
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.error || GET_TEMPLATE_DATA_MESSAGE[0];

      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL DELETE WRITTEN REPORT  ==========
export const deleteWrittenReport = createAsyncThunk(
  "deleteWrittenReport",
  async (written_report_id, { dispatch, rejectWithValue }) => {
    try {
      const response = await dlt(
        `/aimodels/delete-written-report/${written_report_id}`
      );
      dispatch(
        openAlert({
          message: WRITTEN_REPORT_DELETE_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.error || WRITTEN_REPORT_DELETE_MESSAGE[1];

      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);
// ========== API CALL GET WRITTEN REPORT TEMPLATE ==========
export const getWrittenReportList = createAsyncThunk(
  "getTemplateOldDataList",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await get("/aimodels/get-written-reports/", "", data);
      return response;
    } catch (error) {
      const message = WRITTEN_REPORT_LIST_MESSAGE[0];

      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL CREATE WRITTEN REPORT ==========
export const resendWrittenReport = createAsyncThunk(
  "resendWrittenReport",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post(
        "/aimodels/resend-written-reports/",
        "",
        data
      );
      dispatch(
        openAlert({
          message: WRITTEN_REPORT_RESEND_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      const message = WRITTEN_REPORT_RESEND_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL GET SCHEDULE REPORTS LIST ==========
export const getScheduleReportList = createAsyncThunk(
  "getScheduleReportList",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await get("/aimodels/get-schedule-reports/", "", data);
      return response;
    } catch (error) {
      const message = SCHEDULE_REPORT_LIST_MESSAGE[0];

      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL CREATE SCHEDULE REPORT ==========
export const createScheduleReports = createAsyncThunk(
  "createScheduleReports",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post(
        "/aimodels/create-schedule-reports/",
        "",
        data
      );
      dispatch(
        openAlert({
          message: SCHEDULE_REPORT_CREATE_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      let message = error?.response?.data?.validation_error
        ? SCHEDULE_REPORT_CREATE_MESSAGE[3] // Validation Error
        : SCHEDULE_REPORT_CREATE_MESSAGE[1];

      if (error?.response?.status === 403) {
        message = SCHEDULE_REPORT_CREATE_MESSAGE[2];
      }
      
      if (![403, 406].includes(error?.response?.status)) {
        dispatch(
          openAlert({
            message: message,
            severity: "error",
          })
        );
      }
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL DELETE SCHEDULE REPORT  ==========
export const deleteScheduleReport = createAsyncThunk(
  "deleteScheduleReport",
  async (schedule_report_id, { dispatch, rejectWithValue }) => {
    try {
      const response = await dlt(
        `/aimodels/delete-schedule-report/${schedule_report_id}`
      );
      dispatch(
        openAlert({
          message: SCHEDULE_REPORT_DELETE_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      const message = SCHEDULE_REPORT_DELETE_MESSAGE[1];

      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL UPDATE SCHEDULE REPORT  ==========
export const updateScheduleReportStatus = createAsyncThunk(
  "updateScheduleStatus",
  async ({ schedule_id, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await post(
        `/aimodels/update-schedule-reports/${schedule_id}`,
        "",
        data
      );

      dispatch(
        openAlert({
          message: SCHEDULE_REPORT_UPDATE_STATUS_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      const message = SCHEDULE_REPORT_UPDATE_STATUS_MESSAGE[1];

      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL GET TEMPLATES DATA  ==========
export const checkScheduleReportData = createAsyncThunk(
  "checkScheduleReportData",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/aimodels/get-template-data/", "", data);
      return response;
    } catch (error) {
      const message = SCHEDULE_REPORT_CHECK_DATA_MESSAGE[0];

      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ==========  REPORTS REDUCER ==========
const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {
    emptyGetTemplateData: (state) => {
      state.get_written_report_template = {
        ...state.get_written_report_template,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },

    emptyCreateWrittentReport: (state) => {
      state.create_written_report = {
        ...state.create_written_report,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },

    emptyResendWrittenReport: (state) => {
      state.resend_written_report = {
        ...state.resend_written_report,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },

    emptyDeleteWrittenReport: (state) => {
      state.delete_written_report = {
        ...state.delete_written_report,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },

    emptyDeleteScheduleReport: (state) => {
      state.delete_schedule_report = {
        ...state.delete_schedule_report,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },
    emptyCheckScheduleReportData: (state) => {
      state.check_schedule_report_data = {
        ...state.check_schedule_report_data,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },

    setScheduleReportList: (state, action) => {
      state.get_schedule_report_list = {
        ...state.get_schedule_report_list,
        data: action.payload,
      };
    },
    setWrittenReportList: (state, action) => {
      state.get_written_report_list = {
        ...state.get_written_report_list,
        data: action.payload,
      };
    },

    emptyCreateScheduleReport: (state) => {
      state.create_schedule_report = {
        ...state.create_schedule_report,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },
    emptyUpdateScheduleReportStatus: (state) => {
      state.update_schedule_report_status = {
        ...state.update_schedule_report_status,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },
  },
  extraReducers: (builder) => {
    //  =================================== CREATE WRITTEN REPORT CASES ===================================
    builder.addCase(createWrittenReport.pending, (state) => {
      state.create_written_report = {
        ...state.create_written_report,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(createWrittenReport.fulfilled, (state, action) => {
      state.create_written_report = {
        ...state.create_written_report,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(createWrittenReport.rejected, (state, action) => {
      state.create_written_report = {
        ...state.create_written_report,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== CREATE WRITTEN REPORT CASES ===================================

    //  =================================== DELETE WRITTEN REPORT DATA CASES ===================================
    builder.addCase(deleteWrittenReport.pending, (state) => {
      state.delete_written_report = {
        ...state.delete_written_report,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(deleteWrittenReport.fulfilled, (state, action) => {
      state.delete_written_report = {
        ...state.delete_written_report,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(deleteWrittenReport.rejected, (state, action) => {
      state.delete_written_report = {
        ...state.delete_written_report,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  ===================================  DELETE WRITTEN REPORT DATA CASES ===================================

    //  =================================== RESEND AGAIN WRITTEN REPORT CASES ===================================
    builder.addCase(resendWrittenReport.pending, (state) => {
      state.resend_written_report = {
        ...state.resend_written_report,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(resendWrittenReport.fulfilled, (state, action) => {
      state.resend_written_report = {
        ...state.resend_written_report,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(resendWrittenReport.rejected, (state, action) => {
      state.resend_written_report = {
        ...state.resend_written_report,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== RESEND AGAIN WRITTEN REPORT CASES ===================================

    //  =================================== GET WRITTEN REPORT DATA CASES ===================================
    builder.addCase(getTemplateData.pending, (state) => {
      state.get_written_report_template = {
        ...state.get_written_report_template,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(getTemplateData.fulfilled, (state, action) => {
      state.get_written_report_template = {
        ...state.get_written_report_template,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(getTemplateData.rejected, (state, action) => {
      state.get_written_report_template = {
        ...state.get_written_report_template,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  ===================================  GET WRITTEN REPORT DATA CASES ===================================

    //  =================================== GET WRITTEN REPORT LIST CASES ===================================
    builder.addCase(getWrittenReportList.pending, (state) => {
      state.get_written_report_list = {
        ...state.get_written_report_list,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(getWrittenReportList.fulfilled, (state, action) => {
      state.get_written_report_list = {
        ...state.get_written_report_list,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(getWrittenReportList.rejected, (state, action) => {
      state.get_written_report_list = {
        ...state.get_written_report_list,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== GET WRITTEN REPORT LIST CASES ===================================

    //  =================================== CREATE SCHEDULE REPORT CASES ===================================
    builder.addCase(createScheduleReports.pending, (state) => {
      state.create_schedule_report = {
        ...state.create_schedule_report,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(createScheduleReports.fulfilled, (state, action) => {
      state.create_schedule_report = {
        ...state.create_schedule_report,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(createScheduleReports.rejected, (state, action) => {
      state.create_schedule_report = {
        ...state.create_schedule_report,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== CREATE SCHEDULE REPORT CASES ===================================

    //  =================================== GET SCHEDULE REPORT LIST CASES ===================================
    builder.addCase(getScheduleReportList.pending, (state) => {
      state.get_schedule_report_list = {
        ...state.get_schedule_report_list,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(getScheduleReportList.fulfilled, (state, action) => {
      state.get_schedule_report_list = {
        ...state.get_schedule_report_list,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(getScheduleReportList.rejected, (state, action) => {
      state.get_schedule_report_list = {
        ...state.get_schedule_report_list,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== GET SCHEDULE REPORT LIST CASES ===================================

    //  =================================== DELETE SCHEDULE REPORT DATA CASES ===================================
    builder.addCase(deleteScheduleReport.pending, (state) => {
      state.delete_schedule_report = {
        ...state.delete_schedule_report,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(deleteScheduleReport.fulfilled, (state, action) => {
      state.delete_schedule_report = {
        ...state.delete_schedule_report,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(deleteScheduleReport.rejected, (state, action) => {
      state.delete_schedule_report = {
        ...state.delete_schedule_report,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== DELETE SCHEDULE REPORT DATA CASES ===================================

    //  =================================== UPDATE SCHEDULE REPORT STATUS DATA CASES ===================================
    builder.addCase(updateScheduleReportStatus.pending, (state) => {
      state.update_schedule_report_status = {
        ...state.update_schedule_report_status,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(updateScheduleReportStatus.fulfilled, (state, action) => {
      state.update_schedule_report_status = {
        ...state.update_schedule_report_status,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(updateScheduleReportStatus.rejected, (state, action) => {
      state.update_schedule_report_status = {
        ...state.update_schedule_report_status,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  ===================================  UPDATE SCHEDULE REPORT STATUS DATA CASES ===================================

    //  =================================== CHECK SCHEDULE REPORT DATA CASES ===================================
    builder.addCase(checkScheduleReportData.pending, (state) => {
      state.check_schedule_report_data = {
        ...state.check_schedule_report_data,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(checkScheduleReportData.fulfilled, (state, action) => {
      state.check_schedule_report_data = {
        ...state.check_schedule_report_data,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(checkScheduleReportData.rejected, (state, action) => {
      state.check_schedule_report_data = {
        ...state.check_schedule_report_data,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== CHECK SCHEDULE REPORT DATA CASES ===================================
  },
});

export const {
  emptyGetTemplateData,
  emptyCreateWrittentReport,
  emptyDeleteWrittenReport,
  emptyResendWrittenReport,
  setWrittenReportList,

  emptyCreateScheduleReport,
  emptyDeleteScheduleReport,
  emptyUpdateScheduleReportStatus,
  setScheduleReportList,
  emptyCheckScheduleReportData,
} = reportSlice.actions;

export default reportSlice.reducer;
