import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commonReduxType } from "../utils/common";
import { post } from "../api/api";
import { openAlert } from "./alertSlice";
import {
  CANCEL_SUBSCRIPTION_MESSAGE,
  CREATE_CHECKOUT_MESAGE,
} from "../const/alertMessage";

// ========== INITIALSTATE ==========
const initialState = {
  create_checkout: commonReduxType,
  create_subscription_checkout: commonReduxType,
  cancel_subscription: commonReduxType,
};

// ========== API CALL CREATE CHECKOUT ==========
export const createCheckout = createAsyncThunk(
  "createCheckout",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/payment/createcheckoutsession", "", data);
      return response;
    } catch (error) {
      const message =
        "Error Occure While Create Checkout Session, Please Try After Some Time.";
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL CREATE CHECKOUT FO SUBSCRIPTIONS ==========
export const createSubscriptionsCheckout = createAsyncThunk(
  "createSubscriptionsCheckout",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post(
        "/payment/createsubscriptioncheckout",
        "",
        data
      );
      return response;
    } catch (error) {
      const message = CREATE_CHECKOUT_MESAGE[0];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL CREATE CHECKOUT FO SUBSCRIPTIONS ==========
export const cancelSubscripionPayment = createAsyncThunk(
  "cancelSubscripionPayment",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/payment/cancelsubscription", "", data);

      dispatch(
        openAlert({
          message: CANCEL_SUBSCRIPTION_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      const message = CANCEL_SUBSCRIPTION_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== PAYMENT REDUCER ==========
const paymentSlice = createSlice({
  name: "paymentSlice",
  initialState,
  reducers: {
    emptyCreateCheckout: (state) => {
      state.create_checkout = {
        is_success: false,
        loading: false,
        data: {},
        error: null,
      };
    },

    emptyCreateSubcriptionCheckout: (state) => {
      state.create_subscription_checkout = {
        is_success: false,
        loading: false,
        data: {},
        error: null,
      };
    },
    emptyCancelSubscription: (state) => {
      state.cancel_subscription = {
        is_success: false,
        loading: false,
        data: {},
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    //  =================================== CREATE CHECKOUT SESSION CASES ===================================
    builder.addCase(createCheckout.pending, (state, action) => {
      state.create_checkout = {
        ...state.create_checkout,
        loading: true,
        is_success: false,
        error: null,
      };
    });
    builder.addCase(createCheckout.fulfilled, (state, action) => {
      state.create_checkout = {
        ...state.create_checkout,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(createCheckout.rejected, (state, action) => {
      state.create_checkout = {
        ...state.create_checkout,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== CREATE CHECKOUT SESSION CASES ===================================

    //  =================================== CREATE SUBSCRIPTIONS CHECKOUT SESSION CASES ===================================
    builder.addCase(createSubscriptionsCheckout.pending, (state, action) => {
      state.create_subscription_checkout = {
        ...state.create_subscription_checkout,
        loading: true,
        is_success: false,
        error: null,
      };
    });
    builder.addCase(createSubscriptionsCheckout.fulfilled, (state, action) => {
      state.create_subscription_checkout = {
        ...state.create_subscription_checkout,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(createSubscriptionsCheckout.rejected, (state, action) => {
      state.create_subscription_checkout = {
        ...state.create_subscription_checkout,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== CREATE SUBSCRIPTIONS CHECKOUT SESSION CASES ===================================

    //  =================================== CANCEL SUBSCRIPTIONS PAYMENT CASES ===================================
    builder.addCase(cancelSubscripionPayment.pending, (state, action) => {
      state.cancel_subscription = {
        ...state.cancel_subscription,
        loading: true,
        is_success: false,
        error: null,
      };
    });
    builder.addCase(cancelSubscripionPayment.fulfilled, (state, action) => {
      state.cancel_subscription = {
        ...state.cancel_subscription,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(cancelSubscripionPayment.rejected, (state, action) => {
      state.cancel_subscription = {
        ...state.cancel_subscription,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== CANCEL SUBSCRIPTIONS PAYMENT CASES ===================================
  },
});

export const {
  emptyCreateCheckout,
  emptyCreateSubcriptionCheckout,
  emptyCancelSubscription,
} = paymentSlice.actions;

export default paymentSlice.reducer;
