import { CircularProgress } from "@mui/material";
import React, { Suspense } from "react";

// ========================= LOADER COMPONET =========================
const Loader = () => {
  return (
    <div className="loader">
      <CircularProgress value={80} size={50} />
    </div>
  );
};

// ========================= WRAPPER FOR LAZY LOADING =========================
const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
};

export default Loadable;
