import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commonReduxType } from "../utils/common";
import { dlt, get, post, put } from "../api/api";
import { openAlert } from "./alertSlice";
import {
  SET_SUB_USER_PASSWORD_MESSAGE,
  SUB_USER_CREATE_MESSAGE,
  SUB_USER_DELETE_MESSAGE,
  SUB_USER_EDIT_MESSAGE,
} from "../const/alertMessage";

// ========== INITIALSTATE ==========
const initialState = {
  create_sub_user: commonReduxType,
  sub_user_list: { ...commonReduxType, data: [] },
  edit_sub_user: commonReduxType,
  verify_sub_user: commonReduxType,
  delete_sub_user: commonReduxType,
  set_sub_user_password: commonReduxType,
};

const limitReactErrorMessage = (role) =>
  `You have reached your limit for creating additional  ${role?.toLowerCase()}s . To create more ${role?.toLowerCase()}s, Please upgrade your plan.`;

// ========== API CALL CREATE SUB USER ==========
export const createSubUser = createAsyncThunk(
  "createSubUser",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/account/sub-user-register/", "", data);
      dispatch(
        openAlert({
          message: SUB_USER_CREATE_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      let message =
        (error.response?.data?.email && error?.response?.data?.email[0]) ||
        SUB_USER_CREATE_MESSAGE[1];

      if (error?.response?.status === 403) {
        // == Get Limit Exceed Error
        message = limitReactErrorMessage(data.get("role"));
      }

      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL CREATE SUB USER ==========
export const subUserList = createAsyncThunk("subUserList", async () => {
  try {
    const response = await get("/account/sub-user-list/", "");
    return response;
  } catch (error) {
    throw error;
  }
});

// ========== API CALL VERIFY SUB USER ==========
export const verifySubUser = createAsyncThunk(
  "verifySubUser",
  async (slug, { dispatch, rejectWithValue }) => {
    try {
      const response = await get(`/account/is-password-set/${slug}`, "");
      return response;
    } catch (error) {
      const message = error.response?.data?.error || "User Does Not Exist";
      // dispatch(
      //   openAlert({
      //     message: message,
      //     severity: "error",
      //   })
      // );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);
// ========== API CALL EDIT SUB USER ==========
export const editSubUser = createAsyncThunk(
  "editSubUser",
  async ({ data, user_id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await put(`/account/update-user/${user_id}`, "", data);
      dispatch(
        openAlert({
          message: SUB_USER_EDIT_MESSAGE[0],
          severity: "success",
        })
      );

      return response;
    } catch (error) {
      let message = error.response?.data?.email || SUB_USER_EDIT_MESSAGE[1];

      if (error?.response?.status === 403) {
        // == Get Limit Exceed Error
        message = limitReactErrorMessage(data.get("role"));
      }
      if (error?.response?.status !== 403) {
        dispatch(
          openAlert({
            message: message,
            severity: "error",
          })
        );
      }
      throw rejectWithValue(error.response ? message : error.response);
    }
  }
);

// ========== API CALL DELETE SUB USER ==========
export const deleteSubUser = createAsyncThunk(
  "deleteSubUser",
  async (deleted_id, { dispatch, rejectWithValue }) => {
    try {
      const response = await dlt(`/account/delete-user/${deleted_id}`, "");
      dispatch(
        openAlert({
          message: SUB_USER_DELETE_MESSAGE[0],
          severity: "success",
        })
      );

      return response;
    } catch (error) {
      const message = SUB_USER_DELETE_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL SET SUB USER PASSWORD ==========
export const setSubUserPassword = createAsyncThunk(
  "setSubUserPassword",
  async ({ slug, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await put(
        `/account/set-sub-user-password/${slug}`,
        "",
        data
      );
      dispatch(
        openAlert({
          message: SET_SUB_USER_PASSWORD_MESSAGE[0],
          severity: "success",
        })
      );

      return response;
    } catch (error) {
      const message =
        error?.response?.data?.error || SET_SUB_USER_PASSWORD_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== SUB USERS REDUCER ==========
const subUserSlice = createSlice({
  name: "subUserSlice",
  initialState,
  reducers: {
    emptyCreateSubUser: (state) => {
      state.create_sub_user = {
        ...state.create_sub_user,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },
    emptyEditSubUser: (state) => {
      state.edit_sub_user = {
        ...state.edit_sub_user,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },
    emptyDeleteSubUser: (state) => {
      state.delete_sub_user = {
        ...state.delete_sub_user,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },
    emptyVerifySubUser: (state) => {
      state.verify_sub_user = {
        ...state.verify_sub_user,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },
    emptySetSubUserPassword: (state) => {
      state.set_sub_user_password = {
        ...state.set_sub_user_password,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },
    setSubUserList: (state, action) => {
      state.sub_user_list = {
        ...state.sub_user_list,
        data: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    //  =================================== CREATE SUB USER CASES ===================================
    builder.addCase(createSubUser.pending, (state, action) => {
      state.create_sub_user = {
        ...state.create_sub_user,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(createSubUser.fulfilled, (state, action) => {
      state.create_sub_user = {
        ...state.create_sub_user,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(createSubUser.rejected, (state, action) => {
      state.create_sub_user = {
        ...state.create_sub_user,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== CREATE SUB USER CASES ===================================

    //  =================================== SUB USER LIST CASES ===================================
    builder.addCase(subUserList.pending, (state, action) => {
      state.sub_user_list = {
        ...state.sub_user_list,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(subUserList.fulfilled, (state, action) => {
      state.sub_user_list = {
        ...state.sub_user_list,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(subUserList.rejected, (state, action) => {
      state.sub_user_list = {
        ...state.sub_user_list,
        is_success: false,
        loading: false,
        error: action.error.message,
      };
    });
    //  =================================== SUB USER LIST CASES ===================================

    //  =================================== EDIT SUB USER CASES ===================================
    builder.addCase(editSubUser.pending, (state, action) => {
      state.edit_sub_user = {
        ...state.edit_sub_user,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(editSubUser.fulfilled, (state, action) => {
      state.edit_sub_user = {
        ...state.edit_sub_user,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(editSubUser.rejected, (state, action) => {
      state.edit_sub_user = {
        ...state.edit_sub_user,
        is_success: false,
        loading: false,
        error: action?.payload,
      };
    });
    //  =================================== EDIT SUB USER CASES ===================================

    //  =================================== DELETE SUB USER CASES ===================================
    builder.addCase(deleteSubUser.pending, (state, action) => {
      state.delete_sub_user = {
        ...state.delete_sub_user,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(deleteSubUser.fulfilled, (state, action) => {
      state.delete_sub_user = {
        ...state.delete_sub_user,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(deleteSubUser.rejected, (state, action) => {
      state.delete_sub_user = {
        ...state.delete_sub_user,
        is_success: false,
        loading: false,
        error: action.error.message,
      };
    });
    //  =================================== DELETE SUB USER CASES ===================================

    //  =================================== SET SUB USER PASSWORD CASES ===================================
    builder.addCase(setSubUserPassword.pending, (state, action) => {
      state.set_sub_user_password = {
        ...state.set_sub_user_password,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(setSubUserPassword.fulfilled, (state, action) => {
      state.set_sub_user_password = {
        ...state.set_sub_user_password,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(setSubUserPassword.rejected, (state, action) => {
      state.set_sub_user_password = {
        ...state.set_sub_user_password,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== SET SUB USER PASSWORD CASES ===================================

    //  =================================== VEIRFY SUB USER CASES ===================================
    builder.addCase(verifySubUser.pending, (state, action) => {
      state.verify_sub_user = {
        ...state.verify_sub_user,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(verifySubUser.fulfilled, (state, action) => {
      state.verify_sub_user = {
        ...state.verify_sub_user,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(verifySubUser.rejected, (state, action) => {
      state.verify_sub_user = {
        ...state.verify_sub_user,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== VEIRFY SUB USER CASES ===================================
  },
});

export const {
  emptyCreateSubUser,
  emptyEditSubUser,
  emptyDeleteSubUser,
  emptySetSubUserPassword,
  emptyVerifySubUser,
  setSubUserList,
} = subUserSlice.actions;

export default subUserSlice.reducer;
