import { ThemeProvider } from "@mui/system";
import React from "react";
import { theme } from "./theme";
import { store } from "./store";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import route from "./route";
import { AlertSidebar } from "./component/AlertSidebar";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AlertSidebar />
        <RouterProvider router={route} />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
