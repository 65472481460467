import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commonReduxType } from "../utils/common";
import { dlt, get, post } from "../api/api";
import { openAlert } from "./alertSlice";
import {
  DELETE_INQUIRY_CHAT_MESSAGE,
  INQUIRY_CHAT_MESSAGE,
  INQUIRY_GET_LIST_MESSAGE,
  SEND_INQUIRY_MAIL_MESSAGE,
} from "../const/alertMessage";

// ========== INITIALSTATE ==========
const initialState = {
  selected_inquiry_data_file: commonReduxType,
  inquiry: commonReduxType,
  send_inquiry_mail: commonReduxType,
  view_inquiry: { ...commonReduxType, data: [] },
  delete_inquiry: commonReduxType,
  get_previous_inquiry_data_file: commonReduxType,
};

// ========== API CALL SEND INQUIRY CHAT ==========
export const inquiryChat = createAsyncThunk(
  "inquiryChat",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/aimodels/chat-api/", "", data);
      return response;
    } catch (error) {
      const message = error?.response?.data?.error || INQUIRY_CHAT_MESSAGE[0];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL INQUIRY CHAT LIST ==========
export const viewInquiry = createAsyncThunk(
  "viewInquiryList",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/aimodels/view-inquiry-list/", "", data);
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.error || INQUIRY_GET_LIST_MESSAGE[0];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL INQUIRY SEND MAIL ==========
export const sendInquiryMail = createAsyncThunk(
  "sendInquiryMail",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/aimodels/sendmail-inquiry/", "", data);

      dispatch(
        openAlert({
          message: SEND_INQUIRY_MAIL_MESSAGE[0],
          severity: "success",
        })
      );

      return response;
    } catch (error) {
      const message =
        error?.response?.data?.error || SEND_INQUIRY_MAIL_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL DELETE INQUIRY  ==========
export const deleteInquiry = createAsyncThunk(
  "deleteInquiry",
  async (datasource_id, { dispatch, rejectWithValue }) => {
    try {
      const response = await dlt(
        `/aimodels/delete-inquiry/${datasource_id}`,
        ""
      );

      dispatch(
        openAlert({
          message: DELETE_INQUIRY_CHAT_MESSAGE[0],
          severity: "success",
        })
      );

      return response;
    } catch (error) {
      const message =
        error?.response?.data?.error || DELETE_INQUIRY_CHAT_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL GET  INQUIRY PREVIUS LAST DATA  ==========
export const getRecentInquiryList = createAsyncThunk(
  "getRecentInquiryList",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await get(`/aimodels/view-recent-inquiry-list/`, "");

      return response;
    } catch (error) {
      const message = "";
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== FILES AND DTASOURCE REDUCER ==========
const inquirySlice = createSlice({
  name: "inquirySlice",
  initialState,
  reducers: {
    emptyInquiry: (state) => {
      state.inquiry = {
        is_success: false,
        loading: false,
        data: {},
        error: null,
      };
    },
    selectedInquiryDataFile: (state, action) => {
      state.selected_inquiry_data_file = {
        is_success: false,
        loading: false,
        data: action.payload,
        error: null,
      };
    },

    emptySendMail: (state) => {
      state.send_inquiry_mail = {
        is_success: false,
        loading: false,
        data: {},
        error: null,
      };
    },

    emptyDeleteInquiry: (state) => {
      state.delete_inquiry = {
        is_success: false,
        loading: false,
        data: {},
        error: null,
      };
    },

    setInquiryData: (state, action) => {
      // -------- IS RESENT MEANS SET EMPTY STATE ----------
      state.view_inquiry = {
        ...state.view_inquiry,
        ...(action?.payload?.is_reset
          ? {
              loading: false,
              is_success: false,
              error: null,
            }
          : {}),
        data: action.payload.data,
      };
    },
    setPreviousInquiryDataFile: (state, action) => {
      state.get_previous_inquiry_data_file = {
        ...state.get_previous_inquiry_data_file,
        data: { previous: action.payload },
      };
    },
  },
  extraReducers: (builder) => {
    //  =================================== UPLOAD CASES ===================================
    builder.addCase(inquiryChat.pending, (state) => {
      state.inquiry = {
        ...state.inquiry,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(inquiryChat.fulfilled, (state, action) => {
      state.inquiry = {
        ...state.inquiry,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(inquiryChat.rejected, (state, action) => {
      state.inquiry = {
        ...state.inquiry,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== UPLOAD CASES ===================================

    //  =================================== VIEW INQUIRY CASES ===================================
    builder.addCase(viewInquiry.pending, (state) => {
      state.view_inquiry = {
        ...state.view_inquiry,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(viewInquiry.fulfilled, (state, action) => {
      state.view_inquiry = {
        ...state.view_inquiry,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(viewInquiry.rejected, (state, action) => {
      state.view_inquiry = {
        ...state.view_inquiry,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== VIEW INQUIRY CASES ===================================

    //  =================================== SEND MAIL CASES ===================================
    builder.addCase(sendInquiryMail.pending, (state) => {
      state.send_inquiry_mail = {
        ...state.send_inquiry_mail,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(sendInquiryMail.fulfilled, (state, action) => {
      state.send_inquiry_mail = {
        ...state.send_inquiry_mail,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(sendInquiryMail.rejected, (state, action) => {
      state.send_inquiry_mail = {
        ...state.send_inquiry_mail,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== SEND MAIL CASES ===================================

    //  =================================== DELETE INQUIRY CASES ===================================
    builder.addCase(deleteInquiry.pending, (state) => {
      state.delete_inquiry = {
        ...state.delete_inquiry,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(deleteInquiry.fulfilled, (state, action) => {
      state.delete_inquiry = {
        ...state.delete_inquiry,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(deleteInquiry.rejected, (state, action) => {
      state.delete_inquiry = {
        ...state.delete_inquiry,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== DELETE INQUIRY CASES ===================================

    //  =================================== GET RECENT DATA FILE INQUIRY CASES ===================================
    builder.addCase(getRecentInquiryList.pending, (state) => {
      state.get_previous_inquiry_data_file = {
        ...state.get_previous_inquiry_data_file,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(getRecentInquiryList.fulfilled, (state, action) => {
      state.get_previous_inquiry_data_file = {
        ...state.get_previous_inquiry_data_file,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(getRecentInquiryList.rejected, (state, action) => {
      state.get_previous_inquiry_data_file = {
        ...state.get_previous_inquiry_data_file,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== GET RECENT DATA FILE INQUIRY CASES ===================================
  },
});

export const {
  selectedInquiryDataFile,
  emptyInquiry,
  emptySendMail,
  emptyDeleteInquiry,
  setInquiryData,
  setPreviousInquiryDataFile,
} = inquirySlice.actions;

export default inquirySlice.reducer;
