import axios from "axios";
import { getToken } from "../utils/common";
import { SendErrorToSlack } from "../utils";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 100000,
  headers: {},
});

// Request interceptor to set Authorization header
instance.interceptors.request.use(
  (config) => {
    const authToken = getToken();
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      // Logout the user, clear token or perform any other logout actions
      localStorage.clear();

      window.location.assign('/login');
    }
    return Promise.reject(error);
  }
);

export const get = async (url, params) => {
  return instance
    .get(url, {
      params: params,
    })
    .then((res) => {
      return res.data;
    })
    .catch(async (error) => {
      if (error?.response?.status >= 500 || error?.request?.status === 0) {
        await SendErrorToSlack(error?.response?.data, url, { params });
      }
      throw error;
    });
};

export const post = async (url, params, body) => {
  return instance
    .post(url, body, {
      params: params,
    })
    .then((res) => {
      return res.data;
    })
    .catch(async (error) => {
      if (error?.response?.status >= 500 || error?.request?.status === 0) {
        await SendErrorToSlack(error?.response?.data, url, { params, body });
      }
      throw error;
    });
};

export const put = async (url, params, body) => {
  return instance
    .put(url, body, {
      params: params,
    })
    .then((res) => {
      return res.data;
    })

    .catch(async (error) => {
      if (error?.response?.status >= 500 || error?.request?.status === 0) {
        await SendErrorToSlack(error?.response?.data, url, { params, body });
      }
      throw error;
    });
};

export const dlt = async (url, params, body) => {
  return instance
    .delete(url, body, {
      params: params,
    })
    .then((res) => {
      return res.data;
    })

    .catch(async (error) => {
      if (error?.response?.status >= 500 || error?.request?.status === 0) {
        await SendErrorToSlack(error?.response?.data, url, { params, body });
      }
      throw error;
    });
};
