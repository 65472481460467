import { Typography } from "@mui/material";
import _ from "lodash";
import { theme_color } from "../theme";
import { USER_ROLE } from "../const";
import { FilterGraphType } from ".";

export const commonReduxType = {
  loading: false,
  data: {},
  error: null,
  is_success: false,
};

// TO SET TOKEN IN LOCAL STORAGE
export const setToken = (token) => localStorage.setItem("authToken", token);

// TO GET TOKEN IN LOCAL STORAGE
export const getToken = () => localStorage.getItem("authToken");

// TO REMOVE TOKEN IN LOCAL STORAGE
export const removeToken = () => localStorage.removeItem("authToken");

// TO RENAME THE TITLE IN DASHBOARD
export const GET_TITLE = (title) => (title ? title?.split("_")?.join(" ") : "");

// TO GET TITLE IF EXIST AND IF NOT THEN RETURN QESTION
export const SET_TITLE = (data) => {
  const title = data?.response?.response?.title || data?.response?.title;
  if (!title) {
    const question =
      data?.response?.response?.question ||
      data?.response?.question ||
      data?.question;
    const customTitle = `${question?.slice(0, 50)}${
      question?.length >= 50 ? "...." : ""
    }`;
    return customTitle;
  }
  return title;
};

// ===================================== MODIFY THE LABEL AND GET LABEL FOR CHART =================================
/**
 ** Filter out response based on chart type and Return array of labels
 * @param {Object} data Data is object of response of dashboard query
 */
export const MODIFY_CATEGRY_OR_LABEL_FOR_CHART = (data) => {
  const dataRes = data || {};
  let FinalLabels = [];

  // =============== IF LABELS PRESENT =============
  _.has(dataRes, "labels")
    ? (FinalLabels = dataRes.labels)
    : _.has(dataRes, "label")
    ? (FinalLabels = dataRes.label)
    : _.has(dataRes, "lable")
    ? (FinalLabels = dataRes.lable)
    : (FinalLabels = dataRes.lables || []);

  // ========= IF LABELS NOT PRESENT THEN CHECK DATA AND MODIFIED  ============
  if (!FinalLabels?.length) {
    if (
      _.isArray(dataRes?.data) &&
      !_.every(
        dataRes?.data,
        (res) => typeof res === "string" || typeof res === "number"
      )
    ) {
      const data = dataRes?.data;
      const modifiedData = _.map(data, (value, key) => {
        if (_.isObject(value)) {
          const Object = _.filter(value, (values, keys) => {
            if (!_.isObject(values) && _.isString(values)) {
              return values;
            }
            return values[keys];
          });
          return Object;
        }
        return value[key];
      });
      FinalLabels = _.flattenDeep(modifiedData);
    }
  }
  if (
    _.isArray(FinalLabels) &&
    _.every(
      FinalLabels,
      (res) => typeof res === "string" || typeof res === "number"
    )
  ) {
    return FinalLabels || [];
  }
};

// ===================================== MODIFY THE DATA OF SERIES FOR CHART =================================
/**
 ** Filter of response based on chart type
 * @param {Array} dataRes Data is Array of response of dashboard query
 */
export const MODIFY_GET_SERIES_FOR_CHART = (dataRes) => {
  if (
    _.isArray(dataRes?.data) &&
    _.every(
      dataRes?.data,
      (res) =>
        typeof res === "string" || typeof res === "number" || _.isNull(res)
    )
  ) {
    return dataRes?.data?.map((v) => Number(v) || v || 0) || [];
  } else {
    const data = dataRes?.data;
    const modifiedData = _.map(data, (value, key) => {
      if (_.isObject(value)) {
        const Object = _.filter(value, (values, keys) => {
          if (!_.isObject(values) && _.isNumber(values)) {
            return values;
          }
          return values[keys];
        });
        return Object;
      }
      return value || 0;
    });
    return _.flattenDeep(modifiedData);
  }
};

//  ============================== BOUNDING LOADER ==========================
export const BouncingDotsLoader = () => (
  <div className="bouncing-loader">
    <div></div>
    <div></div>
    <div></div>
  </div>
);

// ==================== CHECK IS ADMIN OR MEMBER ====================
/**
 ** Check login user is admin or member
 * @param {Object} userData data of users Object
 */
export const isAdmin = (userData) => {
  return userData?.role === USER_ROLE.ADMIN ? true : false;
};

// ================ TO SHOW HOURS AND MINUTES IN CHATS =================
/**
 ** View Date in inquiry charts Hours and minutes
 * @param {Date} date date
 */
export const ViewDate = (date) => (
  <Typography
    fontSize={"12px"}
    sx={{
      position: "absolute",
      right: "10px",
      bottom: "8px",
    }}
    color={theme_color.gray_color[700]}
  >
    {new Date(date).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}
  </Typography>
);
// ========================  COMMON FUNCTION OF MAKE FILTER CHART FILTER ========================
/**
 ** Filter our response based on chart type
 * @param {Array} datas Data is Array of response of dashboard query
 */
export const FilterChartArray = (datas) => {
  const setDummyData = (itm, chartType) => {
    const staticData = {
      data: [1, 4, 3, 8, 1],
      label: [
        "2023-01-01",
        "2023-05-02",
        "2023-01-03",
        "2023-04-12",
        "2023-02-15",
      ],
    };

    const dataRes = itm?.response?.response || itm?.response;
    if (!dataRes?.data?.length && !dataRes?.lable?.length) {
      return {
        graph: chartType,
        data: {
          ...itm,
          response: {
            response: {
              ...dataRes,
              ...staticData,
            },
          },
        },
      };
    }

    return {
      graph: chartType,
      data: itm,
    };
  };
  const barChart = FilterGraphType(datas, "bar");

  const pieChart = FilterGraphType(datas, "pie");

  const line_chart = FilterGraphType(datas, "line");

  const FinalData = [
    ..._.map(barChart, (itm) => setDummyData(itm, "bar")),

    ..._.map(pieChart, (itm) => setDummyData(itm, "pie")),

    ..._.map(line_chart, (itm) => setDummyData(itm, "line")),
  ];

  return FinalData;
};
