import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commonReduxType } from "../utils/common";
import { dlt, get, post } from "../api/api";
import { openAlert } from "./alertSlice";
import {
  TEMPLATE_CREATE_MESSAGE,
  TEMPLATE_DELETE_MESSAGE,
  TEMPLATE_GET_LIST_MESSAGE,
  TEMPLATE_UPDATE_MESSAGE,
} from "../const/alertMessage";

// ========== INITIALSTATE ==========
const initialState = {
  create_template: commonReduxType,
  get_templates: { ...commonReduxType, data: [] },
  update_templates: commonReduxType,
  delete_templates: commonReduxType,
};

// ========== API CALL CREATE TEMPLATES ==========
export const createTemplate = createAsyncThunk(
  "createTemplate",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/aimodels/template/create/", "", data);
      dispatch(
        openAlert({
          message: TEMPLATE_CREATE_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.error || TEMPLATE_CREATE_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL GET TEMPLATES ==========
export const getTemplates = createAsyncThunk(
  "getTemplates",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await get("/aimodels/template/", "");

      return response;
    } catch (error) {
      const message =
        error?.response?.data?.error || TEMPLATE_GET_LIST_MESSAGE[0];

      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL DELETE TEMPLATES ==========
export const deleteTemplates = createAsyncThunk(
  "deleteTemplates",
  async ({ template_id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await dlt(`/aimodels/template/${template_id}/delete/`);
      dispatch(
        openAlert({
          message: TEMPLATE_DELETE_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      const message = TEMPLATE_DELETE_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL UPDATE TEMPLATES ==========
export const updateTemplates = createAsyncThunk(
  "updateTemplates",
  async ({ data, id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/aimodels/template/", id, data);
      dispatch(
        openAlert({
          message: TEMPLATE_UPDATE_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      const message = TEMPLATE_UPDATE_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== TEMPLATE REDUCER ==========
const templateSlice = createSlice({
  name: "templateSlice",
  initialState,
  reducers: {
    setNewTemplates: (state, action) => {
      state.get_templates = {
        ...state.get_templates,
        data: [...state.get_templates.data, action.payload],
      };
    },
    emptyCreateTemplate: (state) => {
      state.create_template = {
        is_success: false,
        loading: false,
        data: {},
        error: null,
      };
    },
    emptyDeletedTemplates: (state) => {
      state.delete_templates = {
        is_success: false,
        loading: false,
        data: {},
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    //  =================================== CREATE TEMPLATE CASES ===================================
    builder.addCase(createTemplate.pending, (state, action) => {
      state.create_template = {
        ...state.create_template,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(createTemplate.fulfilled, (state, action) => {
      state.create_template = {
        ...state.create_template,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(createTemplate.rejected, (state, action) => {
      state.create_template = {
        ...state.create_template,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== CREATE TEMPLATE CASES ===================================

    //  =================================== GET TEMPLATE CASES ===================================
    builder.addCase(getTemplates.pending, (state, action) => {
      state.get_templates = {
        ...state.get_templates,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      state.get_templates = {
        ...state.get_templates,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(getTemplates.rejected, (state, action) => {
      state.get_templates = {
        ...state.get_templates,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== GET TEMPLATE CASES ===================================

    //  =================================== DELETE TEMPLATE CASES ===================================
    builder.addCase(deleteTemplates.pending, (state, action) => {
      state.delete_templates = {
        ...state.delete_templates,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(deleteTemplates.fulfilled, (state, action) => {
      state.delete_templates = {
        ...state.delete_templates,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(deleteTemplates.rejected, (state, action) => {
      state.delete_templates = {
        ...state.delete_templates,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== DELETE TEMPLATE CASES ===================================

    //  =================================== UPDATE TEMPLATE CASES ===================================
    builder.addCase(updateTemplates.pending, (state, action) => {
      state.update_templates = {
        ...state.update_templates,
        loading: true,
        is_success: false,
        error: null,
      };
    });
    builder.addCase(updateTemplates.fulfilled, (state, action) => {
      state.update_templates = {
        ...state.update_templates,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(updateTemplates.rejected, (state, action) => {
      state.update_templates = {
        ...state.update_templates,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== UPDATE TEMPLATE CASES ===================================
  },
});
export const { emptyCreateTemplate, emptyDeletedTemplates, setNewTemplates } =
  templateSlice.actions;

export default templateSlice.reducer;
