import { Alert, AlertTitle } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeAlert } from "../redux/alertSlice";

const AUTO_DISMISS = 5000;
export const AlertSidebar = () => {
  const dispatch = useDispatch();
  const { isOpen, message, severity, title } = useSelector(
    (state) => state.alert
  );

  const hideAlert = () => {
    dispatch(closeAlert());
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        dispatch(closeAlert());
      }, 5000);
    }
  }, [dispatch, isOpen]);

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={AUTO_DISMISS}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={hideAlert}
        severity={severity}
        sx={{ width: "100%", display: "flex", alignItems: "center" }}
        // variant={variant}
        // action={action}
        // color={color}
      >
        {title && <AlertTitle sx={{ marginBottom: "0px" }}>{title}</AlertTitle>}

        {message}
      </Alert>
    </Snackbar>
  );
};
