import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commonReduxType } from "../utils/common";
import { get } from "../api/api";
import { GET_PROFILE_DATA } from "../const/alertMessage";

// ========== INITIALSTATE ==========
const initialState = {
  user: commonReduxType,
};

// ========== API CALL LOGIN ==========
export const profile = createAsyncThunk(
  "profile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await get("/account/profile/", "");
      return response;
    } catch (error) {
      
      const message = GET_PROFILE_DATA[0];

      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== PROFILE REDUCER ==========
const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  =================================== PROFILE CASES ===================================
    builder.addCase(profile.pending, (state, action) => {
      state.user = {
        ...state.user,
        loading: true,
        is_success: false,
        error: null,
      };
    });
    builder.addCase(profile.fulfilled, (state, action) => {
      state.user = {
        ...state.user,
        loading: false,
        is_success: true,
        data: action.payload,
      };
    });
    builder.addCase(profile.rejected, (state, action) => {
      state.user = {
        ...state.user,
        loading: false,
        is_success: false,
        error: action.error.message,
      };
    });
    //  =================================== PROFILE CASES ===================================
  },
});

export default profileSlice.reducer;
