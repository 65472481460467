import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commonReduxType } from "../utils/common";
import { dlt, post, put } from "../api/api";
import { openAlert } from "./alertSlice";
import {
  ADD_TILES_MESSAGE,
  DELETE_TILE_MESSAGE,
  EDIT_TILES_MESSAGE,
  GET_DASHBOARD_DATA_MESSAGE,
  SEND_MAIL_MESSAGE,
} from "../const/alertMessage";

// ========== INITIALSTATE ==========
const initialState = {
  // selected_query data object { template :{ name:"", value: "" }, datafile:{ name:"", value: "" }}
  seleted_query: commonReduxType,
  add_tiles: commonReduxType,
  edit_tiles: commonReduxType,
  dashboard_query: commonReduxType,
  delete_tiles: commonReduxType,
  send_mail: commonReduxType,
};

// ========== API CALL DASHBOARD QUERY ==========
export const dashboardQuery = createAsyncThunk(
  "dashboardQuery",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/aimodels/static-dasboard-query/", "", data);

      return response;
    } catch (error) {
      const message =
        error?.response?.data?.error || GET_DASHBOARD_DATA_MESSAGE[0];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL FOR ONLY TEMPLATE WILL CHANGE NAD QUEYR IN TEMPLATE WISE DASHBOARD QUERY ==========
export const templateWiseDashoardQuery = createAsyncThunk(
  "templateWiseDashoardQuery",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post(
        "/aimodels/template-dashboard-query/",
        "",
        data
      );

      return response;
    } catch (error) {
      const message =
        error?.response?.data?.error || GET_DASHBOARD_DATA_MESSAGE[0];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL ADD TILES ==========
export const addTiles = createAsyncThunk(
  "addTiles",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/aimodels/add-tiles/", "", data);
      dispatch(
        openAlert({ message: ADD_TILES_MESSAGE[0], severity: "success" })
      );

      return response;
    } catch (error) {
      const message = error?.response?.data?.error || ADD_TILES_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL DELETE TILES ==========
export const deleteTiles = createAsyncThunk(
  "deleteTiles",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await dlt(`/aimodels/delete-tiles/${data}`);
      dispatch(
        openAlert({
          message: DELETE_TILE_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      const message = error?.response?.data?.error || DELETE_TILE_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL EDITS DATASOURCE ==========
export const editTiles = createAsyncThunk(
  "editTiles",
  async ({ tile_id, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await put(`/aimodels/edit-tiles/${tile_id}`, "", data);
      dispatch(
        openAlert({
          message: EDIT_TILES_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      const message = error?.response?.data?.error || EDIT_TILES_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL SEND DATASOURCE ==========
export const sendEmail = createAsyncThunk(
  "sendEmail",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post(`/aimodels/sendmail-dashboard/`, "", data);
      dispatch(
        openAlert({
          message: SEND_MAIL_MESSAGE[0],
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      const message = error?.response?.data?.error || SEND_MAIL_MESSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ==========  DASHBOARD REDUCER ==========
const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    // Regular reducers can be added here if needed
    selectDashboardFields: (state, action) => {
      state.seleted_query = {
        loading: false,
        data: {
          ...state.seleted_query.data,
          ...action.payload,
        },
        error: null,
      };
    },
    setAddTileResponse: (state, action) => {
      state.dashboard_query = {
        loading: false,
        data: {
          ...state.seleted_query.data,
          ...action.payload,
        },
        error: null,
      };
    },
    modifiedDashboardResponse: (state, action) => {
      const { is_reset, data } = action.payload;
      state.dashboard_query = {
        ...state.dashboard_query,
        ...(is_reset
          ? {
              loading: false,
              is_success: false,
              error: null,
            }
          : {}),
        data: data || [],
      };
    },
    emptyAddTiles: (state) => {
      state.add_tiles = {
        ...state.add_tiles,
        is_success: false,
        loading: false,
        data: {},
      };
    },
    emptyDeleteTiles: (state) => {
      state.delete_tiles = {
        is_success: false,
        loading: false,
        data: {},
        error: null,
      };
    },
    emptyEditTiles: (state) => {
      state.edit_tiles = {
        is_success: false,
        loading: false,
        data: {},
        error: null,
      };
    },
    emptysendEmail: (state) => {
      state.send_mail = {
        is_success: false,
        loading: false,
        data: {},
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    //  =================================== ADD TILES CASES ===================================
    builder.addCase(addTiles.pending, (state, action) => {
      state.add_tiles = {
        ...state.add_tiles,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(addTiles.fulfilled, (state, action) => {
      state.add_tiles = {
        ...state.add_tiles,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(addTiles.rejected, (state, action) => {
      state.add_tiles = {
        ...state.add_tiles,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== ADD TILES CASES ===================================

    //  =================================== STATIC DASHBOARD CASES ===================================
    builder.addCase(dashboardQuery.pending, (state, action) => {
      state.dashboard_query = {
        ...state.dashboard_query,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(dashboardQuery.fulfilled, (state, action) => {
      state.dashboard_query = {
        ...state.dashboard_query,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(dashboardQuery.rejected, (state, action) => {
      state.dashboard_query = {
        ...state.dashboard_query,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== STATIC DASHBOARD CASES ===================================

    //  =================================== TEMPLATE WISE STATIC DASHBOARD CASES ===================================
    builder.addCase(templateWiseDashoardQuery.pending, (state, action) => {
      state.dashboard_query = {
        ...state.dashboard_query,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(templateWiseDashoardQuery.fulfilled, (state, action) => {
      state.dashboard_query = {
        ...state.dashboard_query,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(templateWiseDashoardQuery.rejected, (state, action) => {
      state.dashboard_query = {
        ...state.dashboard_query,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== TEMPLATE WISE STATIC DASHBOARD CASES ===================================

    //  =================================== DELETE TILES CASES ===================================
    builder.addCase(deleteTiles.pending, (state, action) => {
      state.delete_tiles = {
        ...state.delete_tiles,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(deleteTiles.fulfilled, (state, action) => {
      state.delete_tiles = {
        ...state.delete_tiles,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(deleteTiles.rejected, (state, action) => {
      state.delete_tiles = {
        ...state.delete_tiles,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== DELETE TILES CASES ===================================

    //  =================================== EDITS TILES CASES ===================================
    builder.addCase(editTiles.pending, (state, action) => {
      state.edit_tiles = {
        ...state.edit_tiles,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(editTiles.fulfilled, (state, action) => {
      state.edit_tiles = {
        ...state.edit_tiles,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(editTiles.rejected, (state, action) => {
      state.edit_tiles = {
        ...state.edit_tiles,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== EDITS TILES CASES ===================================

    //  =================================== SEND MAIL CASES ===================================
    builder.addCase(sendEmail.pending, (state, action) => {
      state.send_mail = {
        ...state.send_mail,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(sendEmail.fulfilled, (state, action) => {
      state.send_mail = {
        ...state.send_mail,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(sendEmail.rejected, (state, action) => {
      state.send_mail = {
        ...state.send_mail,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== SEND MAIL CASES ===================================
  },
});
export const {
  selectDashboardFields,
  setAddTileResponse,
  modifiedDashboardResponse,
  emptyDeleteTiles,
  emptyEditTiles,
  emptyAddTiles,
  emptysendEmail,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
