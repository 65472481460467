import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../src/redux/authSlice";
import alertReducer from "./redux/alertSlice";
import profileReducer from "./redux/profileSlice";
import datasourceSlice from "./redux/dataSourceSlice";
import templateSlice from "./redux/templateSlice";
import subUserSlice from "./redux/subUserSlice";
import dashboardSlice from "./redux/dashboardSlice";
import inquirySlice from "./redux/inquirySlice";
import reportSlice from "./redux/reportSlice";
import paymentSlice from "./redux/paymentSlice";

const rootReducer = combineReducers({
  profile: profileReducer,
  auth: authReducer,
  alert: alertReducer,
  datasource: datasourceSlice,
  template: templateSlice,
  subuser: subUserSlice,
  dashboard: dashboardSlice,
  inquiry: inquirySlice,
  report: reportSlice,
  payment: paymentSlice,
  // Add other reducers here if needed
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
