import { createTheme } from "@mui/material";

export const theme_color = {
  primary_color: "#2E94CD",
  gray_color: {
    400: "#F1F3F6",
    500: "#999999",
    // 600: "#A1A4AC21",
    600: "#0000003B",
    700: "#555555",
  },
  white_color: "#FFFFFF",
  landing_fc: "#7F7F7F",
};
const fontFamily = `"Baloo Bhai 2", sans-serif !important`;

export const theme = createTheme({
  palette: {
    primary: {
      main: theme_color.primary_color,
    },
    common: {
      white: theme_color.white_color,
    },
    grey: theme_color.gray_color,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          background: "#F1F3F6",
          borderRadius: "10px",
          " & input": {
            color: theme_color.gray_color[700],
            minHeight: "55px",
            height: "55px",
            padding: "0px 14px",
            "&::placeholder": {
              fontWeight: 450,
              opacity: 0.7,
            },
          },
          "& .MuiOutlinedInput-root": {
            fontFamily: fontFamily,
            paddingRight: "0px",
            borderRadius: "10px",
          },
          "& .MuiInputAdornment-root": {
            fontFamily: fontFamily,
            height: "100%",
            minHeight: "55px",
            marginRight: "0px",
            background: theme_color.primary_color,
            maxHeight: "fit-content",
            minWidth: "60px",
            justifyContent: "center",
            borderRadius: "8px",
            "& .MuiSvgIcon-root": {
              fill: theme_color.white_color,
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "0px",
          },
          "& .Mui-error .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #d32f2f",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          gap: "0px",
          "& .MuiFormLabel-root": {
            fontFamily: fontFamily,
            textAlign: "start",
            color: theme_color.gray_color[700],
          },
          "& .MuiFormHelperText-root": {
            fontFamily: fontFamily,
            color: "red",
            fontSize: "14px",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          marginLeft: "0px",
          fontSize: "15px !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
          textTransform: "none",
          borderRadius: "8px",
          minHeight: "50px",
          fontSize: "16px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
          variants: [
            {
              props: { variant: "header" },
              style: {
                fontSize: "16px",
                fontWeight: "500",
                color: theme_color.primary_color,
              },
            },
          ],
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: theme_color.primary_color,
          textDecoration: "none",
        },
      },
    },
  },
});
