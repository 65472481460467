import { Box, List, ListItem } from "@mui/material";
import html2pdf from "html2pdf.js";
import _ from "lodash";
import { BREAK_CSS_PDF_CLASS } from "../const";
import axios from "axios";
import { loadCustomFont } from "./fontFamilyPdf";
import { PDFDocument } from "pdf-lib";

// ==================== Define options ====================
const PDFOPTIONS = {
  margin: [50, 0, 15, 0],
  filename: "download.pdf",
  image: { type: "jpeg", quality: 0.7 },
  html2canvas: { scale: 3 },
  pagebreak: {
    // mode: ["css", "avoid-all"],
    mode: "css",
    after: `.${BREAK_CSS_PDF_CLASS}`,
  },
  jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
};

const loadImage = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.src = url;
  });
};

const addPageNumbers = async (pdf) => {
  try {
    const getPdfNumber = pdf.internal.getNumberOfPages();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const totalPages = getPdfNumber + 1;

    for (let i = 1; i < totalPages; i++) {
      pdf.setPage(i);
      const pageNumbers = i;

      // ======== Add Footer Page Number =================
      pdf.setFontSize(10);
      pdf.text(
        `Page ${pageNumbers} of ${getPdfNumber}`,
        pdfWidth / 2 - 10,
        pdfHeight - 8
      );
    }

    return pdf;
  } catch (errors) {
    console.log("🚀 ~ errors:", errors);
  }
};

const elementRefData = (data) => {
  const container = document.createElement("div");

  for (let index = 0; index < data.length; index++) {
    const element = data[index];

    // element.style.marginTop = `${index * 6.5}px`; // Adjust as needed
    if (!element) continue;

    container.appendChild(element.cloneNode(true));
  }
  return container;
};

// ======== Add Big Logo , Template Name , Type of Report in First Page =================================
const addFirstpageContent = async (pdf, reportName, is_dashboard) => {
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  // Add a blank page as the first page
  pdf.insertPage(1); // Inserts a blank page at index 1
  pdf.setPage(1); // Set focus to the first page

  const bigLogoUrl = `${window.location.origin}/final-logo-01.png`;
  const bigLogoImg = await loadImage(bigLogoUrl);
  pdf.addImage(
    bigLogoImg,
    "PNG",
    pdfWidth / 2 - 60,
    pdfHeight / 2 - 40,
    120,
    40,
    "someAlias",
    "FAST"
  );

  if (reportName) {
    pdf.setFontSize(20);
    pdf.text(`${reportName}`, pdfWidth / 2, pdfHeight / 2 + 10, {
      align: "center",
    });

    pdf.text(
      `${!is_dashboard ? "Written Report" : "Dashboard"}`,
      pdfWidth / 2,
      pdfHeight / 2 + 10 + 10,
      { align: "center" }
    );
  }
  // // Move back to the page 2
  pdf.setPage(2); // Set focus to the second page, where content starts

  return pdf;
};

// ============== Add Top Report name and Right side logo =============================
const addLogoAndReportName = async (pdf, reportName, is_dashboard) => {
  const totalPages = pdf.internal.getNumberOfPages() + 1;

  const logoUrl = `${window.location.origin}/logo.png`;
  const logoWidth = 30;

  const logoImg = await loadImage(logoUrl);
  for (let i = 1; i < totalPages; i++) {
    pdf.setPage(i);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    // ======== Add Template and Report Report name ========
    if (reportName) {
      pdf.setFontSize(12);
      pdf.text(
        5,
        15,
        `${reportName} ${!is_dashboard ? "Written" : "Dashboard"} Report`
      );
    }

    // ======= Add Right size Small Logo =================
    pdf.addImage(logoImg, "PNG", pdfWidth - 45, 3, logoWidth, 20);

    // ======= Draw a Bottom Line =================
    pdf.setLineCap(2);
    pdf.setDrawColor(128, 128, 128); // draw black lines
    pdf.line(0, 25, pdfWidth, 25); // add line
  }

  return pdf;
};
/**
 ** Format and return pdf response
 * @param {Array} data data to generate pdf
 * @param {String} reportName Report name or Template Name
 * @param {Boolean} is_dashboard True/False make pdf is Dashboard or written reports
 */
export const GeneratePdfContentForDashboard = async (
  data,
  reportName,
  is_dashboard
) => {
  async function generatePdfBlob(element, index) {
    return new Promise((resolve, reject) => {
      html2pdf()
        .set(PDFOPTIONS)
        .from(element)
        .toPdf()
        .get("pdf")
        .then(async (pdf) => {
          // ========= Set Custom Ballo Bhai Font in Pdf =========
          loadCustomFont(pdf);
          pdf.setFontType("normal");
          pdf.setFont("BalooBhai");
          // ========= Set Custom Ballo Bhai Font in Pdf =========

          if (index === 0) {
            pdf = await addFirstpageContent(pdf, reportName, reportName);
          }
          pdf = await addLogoAndReportName(pdf, reportName, is_dashboard);

          return pdf;
        })
        .outputPdf("blob") // Specify output as Blob
        .then(async (pdfBlob) => {
          resolve(pdfBlob); // Return the generated Blob
        })
        .catch((error) => {
          reject(error); // Handle errors
        });
    });
  }

  const dataSliceCount = 20;
  const totalGetSlicesCount = Math.ceil(data?.length / dataSliceCount);
  const pdfFiles = [];
  for (let i = 0; i < totalGetSlicesCount; i++) {
    const sliceData = data.slice(i * dataSliceCount, (i + 1) * dataSliceCount);
    const container = elementRefData(sliceData);

    const pdfBlob = await generatePdfBlob(container, i);
    pdfFiles.push(pdfBlob);
  }

  // Create a new PDF document to store the merged result
  const mergedPdfDoc = await PDFDocument.create();

  for (let i = 0; i < pdfFiles.length; i++) {
    // Convert Blob to ArrayBuffer
    const pdfBytes = await pdfFiles[i].arrayBuffer();
    const existingPdfDoc = await PDFDocument.load(pdfBytes); // Load the external PDF using pdf-lib

    // Copy all pages from the existing PDF
    const copiedPages = await mergedPdfDoc.copyPages(
      existingPdfDoc,
      existingPdfDoc.getPageIndices()
    );

    // Add copied pages to the merged PDF
    copiedPages.forEach((page) => mergedPdfDoc.addPage(page));
  }

  const totalPages = mergedPdfDoc.getPageCount();

  if (is_dashboard) {
    mergedPdfDoc.removePage(totalPages - 1);
  }

  mergedPdfDoc.getPages().forEach(async (page, index) => {
    const { width, height } = page.getSize();

    const pageNumberText = `Page ${index + 1} of ${
      totalPages - (is_dashboard ? 1 : 0)
    }`;

    // Draw the page number at the bottom center of the page
    page.drawText(pageNumberText, {
      x: width / 2 - 30,
      y: height - height + 20,
      size: 9,
    });
  });

  const mergedPdfBytes = await mergedPdfDoc.save();

  // Create a Blob from the merged PDF bytes and trigger download
  const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
  // const link = document.createElement("a");
  // link.href = URL.createObjectURL(blob);
  // link.download = "merged.pdf";
  // link.click();
  return blob;
};

/**
 ** *  Format and return pdf response for inquiry module
 * @param {Array} data data to generate pdf
 */
export const GenerateInquiryContentBlobForSendMail = async (data) => {
  const container = elementRefData(data);

  // Convert HTML to PDF
  const dataRes = await html2pdf()
    .from(container)
    .set({
      ...PDFOPTIONS,
      margin: [40, 10, 0, 10],
      html2canvas: { scale: 3 },
      image: { type: "jpeg", quality: 0.8 },
    })
    .toPdf()
    .get("pdf")
    .then(async (pdf) => {
      // ========= Set Custom Ballo Bhai Font in Pdf =========
      loadCustomFont(pdf);
      pdf.setFontType("normal");
      pdf.setFont("BalooBhai");
      // ========= Set Custom Ballo Bhai Font in Pdf =========

      pdf = await addLogoAndReportName(pdf);
      pdf = await addPageNumbers(pdf);
      return pdf;
    });
  return dataRes;
};

const isToday = (date) => {
  const today = new Date();
  return date.toDateString() === today.toDateString();
};

const isYesterday = (date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return date.toDateString() === yesterday.toDateString();
};

/**
 *  FOR FILTER GRAPH TYPE IN DASHBOARD
 * @param {Date} date date to check and modify
 */

export const InquiryDateFormateWise = (date) => {
  const formateDate = new Date(date);
  const today = new Date();

  let dateFormate = "";

  if (isToday(formateDate)) {
    dateFormate = "Today";
  } else if (isYesterday(formateDate)) {
    dateFormate = "Yesterday";
  } else {
    const dateYear = formateDate.getFullYear();
    const todayYear = today.getFullYear();

    dateFormate = `${formateDate.toLocaleString("default", {
      month: "short",
    })}  ${formateDate.getDate()}  ${
      dateYear === todayYear
        ? ""
        : formateDate.toLocaleDateString("default", {
            year: "numeric" || "2-digit",
          })
    }`;
  }

  return dateFormate;
};

/**
 *  For filter graph response based on type
 * @param {Object} data
 * @param {String} type
 */

export const FilterGraphType = (data, type = "bar") => {
  switch (type) {
    case "bar":
      return _.filter(data, (itm) => {
        if (
          itm?.graph_type?.toLowerCase()?.includes("bar") ||
          itm?.question?.includes("bar_chart")
        ) {
          return itm?.data || itm.response;
        }
      });
    case "line":
      return _.filter(data, (itm) => {
        if (
          itm?.graph_type?.includes("line") ||
          itm?.question?.includes("line_chart")
        ) {
          return itm?.data || itm.response;
        }
      });
    case "pie":
      return _.filter(data, (itm) => {
        if (
          itm?.graph_type?.includes("pie") ||
          ["pie_chart", "pieChart", "PieChart", "pie", "piechart"].includes(
            itm?.graph_type
          )
        ) {
          return itm?.data || itm.response;
        }
      });

    default:
      return _.filter(data, (itm) => {
        if (
          itm?.graph_type?.toLowerCase()?.includes("bar") ||
          itm?.question?.includes("bar_chart")
        ) {
          return itm?.data || itm.response;
        }
      });
  }
};

/**
 ** Format and return json string data for show in UI **
 * @param {Array} data data of reponse of api
 */
export const ConverChatData = (data) => {
  let item = typeof data;
  if (_.isArray(data)) {
    item = "array";
  }

  switch (item) {
    case "string":
      return (
        <div>
          {data
            .replace(`"response":`, "")
            .replace("{", "")
            .replace("}", "")
            .replaceAll(`"`, "")}
        </div>
      );
    case "object":
      return (
        <Box>
          {_.map(data, (value, key) => {
            return (
              <pre>
                {key}: {JSON.stringify(value, null, 2)}
              </pre>
            );
          })}
        </Box>
      );
    case "array":
      return (
        <Box>
          <List>
            {data.map((value, idx) => {
              if (_.isString(value)) {
                return <ListItem key={idx}>{value}</ListItem>;
              }
              return (
                <ListItem key={idx}>
                  <pre>{JSON.stringify(value, null, 2)}</pre>
                </ListItem>
              );
            })}
          </List>
        </Box>
      );
    default:
      return <div>{data}</div>;
  }
};

export const SendErrorToSlack = async (payload, section, data = {}) => {
  try {
    let datas = data?.body ? Object.fromEntries(data?.body?.entries()) : {};

    data = { ...data, body: datas };

    const url = `${process.env.REACT_APP_API_BASE_URL}/account/error-slack/`;

    const modifiedPayload = {
      blocks: [
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `:warning: *Hold up!* An error just  FRONT END photobombed the *${section}* zone. Our code's taking unexpected selfies with bugs! :camera_with_flash:`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text:
              " *Error Snapshot* : ```json\n" +
              JSON.stringify(payload, null, 2) +
              "\n```",
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: "```json\n" + JSON.stringify(data, null, 2) + "\n```",
          },
        },
        {
          type: "context",
          elements: [
            {
              type: "mrkdwn",
              text: "Time to debug with style! Let's put on our error-fighting capes and show those bugs who's boss. :superhero::computer:",
            },
          ],
        },
      ],
      username: "Mayank Patel",
    };

    await axios.post(url, { payload: modifiedPayload });
  } catch (e) {
    console.error("🚀 ~ SendErrorToSlack ~ e:", e);
  }
};
